import React from 'react';
import Container from '../Container';
import Logo from '../Logo';
import Link from '../Link';

const Header = () => {
	return (
		<header className="sticky top-0 z-40 bg-white border-b border-gray-100 md:z-50">
			<Container className="mt-0 mb-0 py-6">
				<nav className="justify-between items-center flex">
					<Logo className="text-primary-700" />
					<Link href="https://app.brighthr.com" dataTestId="login-button">
						Log in
					</Link>
				</nav>
			</Container>
		</header>
	);
};

export default Header;
