import React from 'react';

const style =
	'rounded-md font-bold inline-block transition-colors cursor-pointer px-3 py-2 border-2 focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-accent-500 focus:hover:outline-accent-700';

const Link = ({ href, outline, linkType, children, onClick, dataTestId }) =>
	linkType === 'button' ? (
		<button
			data-testid={dataTestId}
			type="button"
			onClick={onClick}
			className={`${style} ${
				outline
					? 'text-accent-500 bg-white border-accent-500 hover:border-accent-700 hover:bg-accent-700 hover:text-white'
					: 'text-white bg-accent-500 border-accent-500 hover:border-accent-700 hover:bg-accent-700'
			}`}
		>
			{children}
		</button>
	) : (
		<a
			data-testid={dataTestId}
			href={href}
			className={`${style} ${
				outline
					? 'text-accent-500 bg-white border-accent-500 hover:border-accent-700 hover:bg-accent-700 hover:text-white'
					: 'text-white bg-accent-500 border-accent-500 hover:border-accent-700 hover:bg-accent-700'
			}`}
		>
			{children}
		</a>
	);

export default Link;
