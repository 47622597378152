import React from 'react';
import PropTypes from 'prop-types';

import Header from '../Header';
import Container from '../Container';

const Layout = ({ children, fullWidth = false }) => {
	return (
		<>
			<link rel="preconnect" href="https://fonts.googleapis.com" />
			<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
			<link
				href="https://fonts.googleapis.com/css2?family=Albert+Sans:wght@300..900&display=swap"
				rel="stylesheet"
			/>
			<script
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{
					__html: `(function(t,e,s,n){var o,a,c;t.SMCX=t.SMCX||[],e.getElementById(n)||(o=e.getElementsByTagName(s),a=o[o.length-1],c=e.createElement(s),c.type="text/javascript",c.async=!0,c.id=n,c.src="https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd5xNu8O2yLjHec9YeuB_2F1z2YfrjvckFrW_2Bf13XGqpnnN.js",a.parentNode.insertBefore(c,a))})(window,document,"script","smcx-sdk")`,
				}}
			/>
			<Header />
			{fullWidth && <main className="min-h-screen text-black">{children}</main>}
			{!fullWidth && (
				<Container className="min-h-screen md:flex">
					<main className="pt-6 md:pl-2 lg:pl-6 md:pt-10">{children}</main>
				</Container>
			)}
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
